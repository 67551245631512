import React from 'react'
import { ShellProps } from '../src/types/shell'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type ShellRedirectMfeType = {
  properties: {
    redirectUrl: string
    sendQueryParams: boolean
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ properties }: ShellRedirectMfeType) {
  const { v1 } = window.Shell as ShellProps
  const { redirectUrl, sendQueryParams } = properties
  const queryParams = v1?.navigation?.location?.search

  window.location.href = sendQueryParams
    ? redirectUrl + queryParams
    : redirectUrl

  return <></>
}
